<template>
  <Component v-bind:is="header" v-bind:props="props" />
</template>

<script>
  export default {
    props: {
      props: {
        type: Object,
        default: function() {
          return {};
        }
      }
    },
    computed: {
      header() {
        return this.$store.getters["system/header"];
      }
    }
  };
</script>